<template>
  <img id="image" src="./assets/Dandelion Logo_for VC_2024.png" alt="Dandelion Logo_for VC_2024.png" />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#text {
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
}
#image {
  width: 100vw;
  height: 100vh;

  object-fit: cover;
}
#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
